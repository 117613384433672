import React, { useState } from "react";
import styles from "./Css/CarrierRemissionGuide.module.scss";
import CustomHeader from '../../General/Components/CustomHeader';
import BackButton from '../../General/Components/BackButton';
import List from './Components/List';
import ListRoadMap from './Components/ListRoadMap';
import JabaForm from './Components/JabaForm';
import Refund from "./Components/Refund";
import HeaderRoadMap from "./Components/HeaderRoadMap";
import { closeCircle } from 'ionicons/icons';
import { IonContent, IonCard, IonCardContent, useIonToast, IonLoading} from "@ionic/react";
import { helpContentList, helpContentRoadMap, helpContentJabaForm, 
helpRefund, helpHeaderRoadMap } from './Data/Data';
import { getRoadMaps, getHeaderRoadMap, createDetailRoadMap, 
getBagControl, getDetailBagControl } from './Services';

function CarrierRemissionGuide() {
    const [present] = useIonToast();
    const [name, setName] = useState('Guia de Remision Transportista');
    const [state, setState] = useState('header');
    const [details, setDetails] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [idStores, setIdStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [idHeaderRoadMap, setIdHeaderRoadMap] = useState('');
    const [freightNumber, setFreightNumber] = useState('');
    const [licensePlate, setLicensePlate] = useState('');
    const [storeCode, setStoreCode] = useState('');
    const [detailsJaba, setDetailsJaba] = useState([]);
    const [headersJaba, setHeadersJaba] = useState([]);
    const [documentSelected, setDocumentSelected] = useState(helpContentList());
    const [orderRefund, setOrderRegund] = useState([]);

    function detailRoadMaps (freight, licensePlate) {
        setLoading(true);
        setFreightNumber(freight);
        setLicensePlate(licensePlate);
        getHeaderRoadMap(freight, licensePlate).then((response) => {
            if(response.status){
                setIdHeaderRoadMap(response.data[0].idHeaderRoadMap);
                getRoadMaps(freight, licensePlate).then((response) => {
                    setLoading(false);
                    setDetails(response.data);
                    setHeaders(response.headers);
                    setIdStores(response.idStores);
                    setState('detail');
                    setName('Hoja de Ruta')
                    setDocumentSelected(helpContentRoadMap());
                });
            }
        });
    }

    function saveRoadMaps(data, freight, licensePlate){
        setLoading(true);
        getHeaderRoadMap(freight, licensePlate).then((response) => {
            if(response.status){
                let idHeader = response.data[0].idHeaderRoadMap;
                setIdHeaderRoadMap(idHeader);
                createDetailRoadMap(idHeader, data).then((response) => {
                    if(response.status){
                        detailRoadMaps(freight, licensePlate);
                    }else {
                        setLoading(false);
                        messageError(response.messages);
                    }
                });
            }else {
                setLoading(false);
                messageError(response.messages);
            }
        });
    };

    function viewJabaForm(codeStore, idHeaderRoadMap, freightNumber, type) {
        setLoading(true);
        setStoreCode(codeStore);
        getBagControl(codeStore, idHeaderRoadMap, freightNumber, type).then((response) => {
            setHeadersJaba(response.data);
            setName('Registro Jabas');
            setDocumentSelected(helpContentJabaForm());
            if(response.status){
                getDetailBagControl(response.data.idHeaderBagControl).then((response) => {
                    setDetailsJaba(response.data);
                    setLoading(false);
                    setState('jaba');
                });
            } else {
                setLoading(false);
                setState('jaba');
            }
        });
    }

    function backView(){
        if(state === 'jaba' || state === 'refund' || state === 'headerRoadMap'){
            setState('detail');
            setName('Hoja de Ruta');
            setDocumentSelected(helpContentRoadMap());
        } else {
            setState('header');
            setName('Guia de Remision Transportista');
            setDocumentSelected(helpContentList());
        }
    }

    function viewRefund(data){
        setState('refund');
        setOrderRegund(data);
        setName('Devoluciones');
        setDocumentSelected(helpRefund());
    }

    function viewHeaderRoadMap(){
        setState('headerRoadMap');
        setName('Cabecera Hoja de Ruta');
        setDocumentSelected(helpHeaderRoadMap());
    }

    function messageError(messages){
        messages.map((message) => (
            present({
                message: message,
                duration: 3000,
                position: 'bottom',
                color: 'secondary',
                icon: closeCircle
            })
        ));
    }

    return (
    <>
        <IonLoading isOpen={loading} message="Cargando..." />
        <CustomHeader name={name} content={documentSelected}/>
        <IonContent scrollY={false}>
            <div className={styles.center}> 
            <IonCard className={styles.ionCard}>
                <IonCardContent>
                    {state === 'header' && (
                        <List save={saveRoadMaps} fn={detailRoadMaps}/>
                    )}
                    {state === 'detail' && (
                        <ListRoadMap 
                            idStores={idStores} 
                            details={details} 
                            headers={headers}
                            idHeaderRoadMap={idHeaderRoadMap}
                            freightNumber={freightNumber}
                            licensePlate={licensePlate} 
                            fn={detailRoadMaps} 
                            viewJabaForm={viewJabaForm}
                            viewRefund={viewRefund}
                            viewHeaderRoadMap={viewHeaderRoadMap}
                        />
                    )}
                    {state === 'jaba' && (
                        <JabaForm 
                            details={detailsJaba} 
                            headers={headersJaba} 
                            type='1' 
                            freightNumber={freightNumber} 
                            storeCode={storeCode}
                            idHeaderRoadMap={idHeaderRoadMap}
                        />
                    )}
                    {state === 'refund' && (
                        <Refund
                            orderRefund={orderRefund}
                            returnDetial={backView}
                        />
                    )}
                    {state === 'headerRoadMap' && (
                        <HeaderRoadMap
                            idHeaderRoadMap={idHeaderRoadMap}
                            returnDetial={backView}
                        />
                    )}
                </IonCardContent>
            </IonCard>
            </div>
            {(state === 'detail' || state === 'jaba' || state === 'headerRoadMap') && (
                <BackButton fn={backView} value='header'/>
            )}
        </IonContent>
    </>
    );
}

export default CarrierRemissionGuide;
