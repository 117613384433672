import HttpClient from '../../services/HttpCilent';
import { returnResponse } from '../../Helper/Helpers';
import { formatSettings } from './Data/Formats';

export const Authenticate = (data) =>{
    return new Promise((resolve) => {
        HttpClient.post(`/api/userValidates`, data).then(resp => {
            if (resp.data.response) {
                sessionStorage.setItem("username", resp.data.data.description);
                sessionStorage.setItem("profileCode", resp.data.data.profileCode);
                sessionStorage.setItem("token", resp.data.data.token);
                sessionStorage.setItem("userCode", resp.data.data.userCode);
            }
            const data = {
                status: resp.data.response,
                messages: (resp.data.messages.length)? resp.data.messages : [resp.data.alert]
            };
            resolve(data);
        })
    })
}

export const validateSendToken = () =>{
    return new Promise((resolve) => {
        HttpClient.get(
            `/api/settingByCountries/getByConfigurationName?configuration=enable2FA`
        ).then(response => {
            resolve(returnResponse(response, formatSettings()));
        })
    })
}

export const SendToken = () =>{
    return new Promise((resolve) => {
        HttpClient.get(`/api/userValidates/sendCodeToMail/edit`).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const SendTokenValidate = (token) =>{
    return new Promise((resolve) => {
        HttpClient.get(
            `/api/userValidates/getByUserAndCode/edit?two_factor_code=`+token
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}