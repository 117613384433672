export function formatData(data, format) {
    let response = [];
    if(data.length){
        data.forEach((element) => {
            let item = {};
            format.map((header) => (
                item[header['rename']] = element[header['name']]
            ));
            response.push(item);
        })
    } else {
        response = {};
        format.map((header) => (
            response[header['rename']] = data[header['name']]
        ));
    }

    return response;
}

export function returnResponse(data, format = [], headers = []) {
    let response = {
        status: data.data.response, 
        data: [],
        headers: headers,
        messages: []
    }

    if(data.data.response && data.data.data){
        response.data = formatData(data.data.data, format);
    } else {
        response.messages = (data.data.messages.length) ? data.data.messages : [data.data.alert];
    }

    return response;
}