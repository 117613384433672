import HttpClient from '../../services/HttpCilent';
import { PhysicalTakeHeaderDetail, PhysicalTakeHeader } from './Data/Data';

export const getPhysicTakeHeaders = (data) => {
    return new Promise((resolve) => {
        let response;
        HttpClient.get('/api/physicTakeHeaders/getPhysicTakeHeadersByRestaurant?'
            + 'status=' + data.status
        ).then(resp => {
            if (resp.data.response) {
                response = {
                    status: resp.data.response,
                    data: resp.data.data,
                    headers: (resp.data.headers.length) ? resp.data.headers : PhysicalTakeHeader()
                };
            } else {
                response = {
                    status: resp.data.response,
                    messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
                };
            }
            resolve(response);
        }).catch(() => {
            const data = {
                status: false,
                messages: ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte'],
            };
            resolve(data);
        })
    })
}

export const getPhysicTakeDetails = (data) => {
    return new Promise((resolve) => {
        let response;
        HttpClient.get('/api/physicTakeDetails/getAll?id_physic_take=' + data.physicTakeHeaderCode
            + '&article_name=' + data.nameArtcicle
            + '&article_code=' + data.codeArticle
            + '&area=' + data.area
            + '&page=' + data.page
            + '&limit=' + data.limit
            + '&is_paginated=' + data.isPaginate
        ).then(resp => {
            if (resp.data.response) {
                response = {
                    status: resp.data.response,
                    data: formatDetail(resp.data.data.data),
                    to: resp.data.data.to,
                    total: resp.data.data.total,
                    headers: (resp.data.headers.length) ? resp.data.headers : PhysicalTakeHeaderDetail()
                };
            } else {
                response = {
                    status: resp.data.response,
                    messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
                };
            }
            resolve(response);
        }).catch(() => {
            const data = {
                status: false,
                messages: ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte'],
            };
            resolve(data);
        })
    })
}

export const updateAmountPhysicalTake = (data) => {
    return new Promise((resolve) => {
        HttpClient.put('/api/physicTakeDetails/' + data.id_artcicle + '?type=basic'
            , data
        ).then(resp => {
            let response = {
                status: resp.data.response,
                messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
            };
            resolve(response);
        }).catch(() => {
            const data = {
                status: false,
                messages: ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte'],
            };
            resolve(data);
        })
    })
}

    
export const getAreas = () =>{
    return new Promise((resolve) => {
        let data;
        HttpClient.get('/api/areasRestaurants/getAreasByRestaurant').then(resp => {
            if(resp.data.response){
                data = {
                    status: resp.data.response,
                    data: resp.data.data
                };
            } else {
                data = {
                    status: resp.data.response,
                    messages: (resp.data.messages.length)? resp.data.messages : [resp.data.alert],
                    data: []
                };
            }
            resolve(data); 
        }).catch(() => {
            const data = {
                status: false,
                messages: ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte']
            };
            resolve(data);
        })
    })
}

function formatDetail(response){
    let data = {};
    response.forEach(function(element) {
        if(data[element.articleCode.trim()] === undefined){
            let item = {};
            let area = {};
            item['name'] = element.articleCode.trim() +' - '+ element.name;
            item['unit'] = element.unitInventory;
            item['article_code'] = element.articleCode.trim();
            item['article_name'] = element.name;
            item['area'] = [];
            item['total'] = element.physicQuantity;
            if(element.areaName.trim().toUpperCase() === 'MOVIL'){
                area['area_code'] = (element.areaCode) ? element.areaCode : 99 ;
                area['area_name'] = element.areaName;
                area['id_article'] = element.warehouseArticleCode;
                area['quantity'] = element.physicQuantity;
                item['area'].push(area);
            }
            data[element.articleCode.trim()] = item;
        } else {
            if(element.areaName.trim().toUpperCase() === 'MOVIL'){
                let area = {}; 
                area['area_code'] = (element.areaCode) ? element.areaCode : 99 ;
                area['area_name'] = element.areaName;
                area['id_article'] = element.warehouseArticleCode;
                area['quantity'] = element.physicQuantity;
                data[element.articleCode.trim()].area.push(area);
                data[element.articleCode.trim()].total += element.physicQuantity;
            }
        }
    });

    return data;
}