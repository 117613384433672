import React, { useState } from "react";
import SearchDetail from './SearchDetail';
import TableDetail from './TableDetail';

const PhysicalTakeDetail = ({ restaurant, physicTakeHeaderCode }) => {
    const [page,    setPage]    = useState(1);
    const [total,   setTotal]   = useState(10);
    const [to,      setTo]      = useState(2);
    const [name,    setName]    = useState('');
    const [code,    setCode]    = useState('');
    const [area,    setArea]    = useState('GENERAL');
    const [look,    setLook]    = useState(false);

    const getPaginate = (e) => {
        setPage(e);
        setLook(!look);
    }

    const setPaginate = (total, to) => {
        setTo(to);
        setTotal(total);
    }

    const searchParameter = (name, code, area) => {
        setName(name);
        setCode(code);
        setArea(area);
        setPage(1);
        setLook(!look);
    }

    const searchAll = () => {
        setName('');
        setCode('');
        setPage(1);
        setLook(!look);
    }

    const fnFilter = (e, filter) => {
        if (filter === 'code') {
            setCode(e.currentTarget.value);
        } else if (filter === 'name') {
            setName(e.currentTarget.value);
        }
    }

    return (
        <div>
            <SearchDetail
                page={page}
                total={total}
                to={to}
                setPage={setPage}
                fnPaginate={getPaginate}
                fnSearch={searchParameter}
                fnSearchAll={searchAll}
                fnFilter={fnFilter}
            />
            <TableDetail
                page={page}
                name={name}
                code={code}
                look={look}
                area={area}
                restaurant={restaurant}
                physicTakeHeaderCode={physicTakeHeaderCode}
                fn={setPaginate}
            />
        </div>
    );
}

export default PhysicalTakeDetail;