import { getPhysicTakeDetails, updateAmountPhysicalTake } from '../../Services';
import { IonRow, IonCol, IonList, IonInput, IonLoading, useIonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import styles from "../../Css/List.module.scss";
import { checkmarkCircleOutline, closeCircle } from 'ionicons/icons';


const List = ({ page, name, code, physicTakeHeaderCode, look, fn, area }) => {
  const [data, setBody] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [present] = useIonToast();

  useEffect(() => {
    setLoading(true);
    setResults({});
    const data = {
      physicTakeHeaderCode: physicTakeHeaderCode,
      nameArtcicle: name,
      codeArticle: code,
      page: page,
      area: area,
      limit: 1000,
      isPaginate: 1
    };
    getPhysicTakeDetails(data).then((response) => {
      if (response.status) {
        if (response.data) {
          setBody(response.data);
          setResults(response.data);
          fn(response.total, response.to);
        }
      } else {
        response.messages.map((message) => (
          present({
            message: message,
            duration: 3000,
            position: 'bottom',
            color: 'danger',
            icon: closeCircle
          })
        ));
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [look]);

  useEffect(() => {
    if (name !== "") {
      const searchTermLower = name.toLowerCase();
      const newResults = Object.entries(results).filter(e => e[1].article_name.toLowerCase().includes(searchTermLower));
      let orderResults = {};
      newResults.forEach(function(element) {
        orderResults[element[0]] = element[1];
      })
      setResults(orderResults);
    } else {
      setResults(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  
  useEffect(() => {
    if (code !== "") {
      const searchTermLower = code.toLowerCase();
      const newResults = Object.entries(results).filter(e => e[1].article_code.toLowerCase().includes(searchTermLower));
      let orderResults = {};
      newResults.forEach(function(element) {
        orderResults[element[0]] = element[1];
      })
      setResults(orderResults);
    } else {
      setResults(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const updateAmount = (item, articleId, area) => {
    const value = document.getElementById(item.id_article + '-' + item.area_code).value;
    if (value !== "" && value !== undefined) {
      const data = {
        type:"basic",
        id_take_phisycal: physicTakeHeaderCode,
        id_artcicle: item.id_article,
        area_code: (item.area_code) ? item.area_code : 99,
        physical_quantity: value
      };
      setLoading(true);
      updateAmountPhysicalTake(data).then((response) => {
        setLoading(false);
        let icon = (response.status) ? checkmarkCircleOutline : closeCircle;
        let color = (response.status) ? 'success' : 'danger';
        if(response.status){
          results[articleId].area[area].quantity = value;
          document.getElementById('total'+articleId).value = calculateTotal(articleId);
          results[articleId].total = calculateTotal(articleId);
        }
        response.messages.map((message) => (
          present({
            message: message,
            duration: 3000,
            position: 'bottom',
            color: color,
            icon: icon
          })
        ));
      });
    }
  }

  function calculateTotal(article) {
      let total = 0;
      results[article].area.forEach(element => {
        total += parseFloat(element.quantity);
      });
      return total;
  }
  const onlyNumber = (e, item) => {
    document.getElementById(item).value = e.currentTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
  }

  const printBody = () => {
    const rowBody = Object.entries(results).map((item, key) => (
      <IonRow key={key} className={styles.employeeItem}>
        <IonCol size='12' align='center'>
          <h2><b>Nombre</b> {item[1].name} <br></br> <b>Unidad</b> {item[1].unit}</h2>
        </IonCol>
        {item[1].area.map((area, keyArea) => {
            return (
              <IonCol size='6' key={keyArea}>
                <h2><b>{area.area_name}</b></h2>
                <IonInput
                  label="Outline input"
                  labelPlacement="floating"
                  fill="outline"
                  id={area.id_article + '-' + area.area_code}
                  placeholder={area.quantity}
                  onBlur={() => updateAmount(area, item[0], keyArea)}
                  onInput={(e) => onlyNumber(e, area.id_article + '-' + area.area_code)}
                />
              </IonCol>
            );
          })
        }
        <IonCol size='6'>
          <h2><b>Total</b></h2>
          <IonInput
            label="Outline input"
            labelPlacement="floating"
            fill="outline"
            value={item[1].total}
            id={'total' + item[0]}
            type="text"
            readonly
          />
        </IonCol>
      </IonRow>
    ));

    return rowBody;
  }

  return (
    <div className={styles.content}>
      <IonLoading isOpen={loading} message="Cargando..." />
      <IonList>
        {printBody()}
      </IonList>
    </div>
  );
};

export default List;
