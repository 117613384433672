/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import './js/components/General/Css/App.css';

import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { AuthRouteComponent } from "./core/Authroute";
import React from 'react';
//RUTAS
import Login from './js/components/Pages/Login/Index';
import PhysicalTake from './js/components/Pages/PhysicalTake/Index';
import Home from './js/components/Pages/Home/Index';
import CarrierRemissio from './js/components/Pages/CarrierRemissionGuide/Index';
import ManagerRemissio from './js/components/Pages/ManagerRemissionGuide/Index';

require('./bootstrap');

setupIonicReact({
  rippleEffect: false,
  mode: 'md'
});


function App() {
  return (
    <IonApp>
        <IonReactRouter>
            <IonRouterOutlet>
                <Route exact path="/">
                
                  <Login />
                </Route>
                <AuthRouteComponent path="/home">
                
                    <Home />
                </AuthRouteComponent>
                <AuthRouteComponent path="/physicalTakes">
                    <PhysicalTake />
                </AuthRouteComponent>
                <AuthRouteComponent path="/guiaRemisions">
                    <CarrierRemissio />
                </AuthRouteComponent>
                <AuthRouteComponent exact path="/guiaRemisionGerentes">
                    <ManagerRemissio />
                </AuthRouteComponent>
            </IonRouterOutlet>
        </IonReactRouter>
    </IonApp>
  );
}

export default App;
