import React, { useEffect } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonIcon
} from "@ionic/react";
import { search, list } from "ionicons/icons";
import CustomPaginatorShort from "../../../../General/Components/CustomPaginatorShort";

const SearchDetail = ({
  page,
  setPage,
  total,
  to,
  fnPaginate,
  fnSearch,
  fnSearchAll,
  fnFilter,
}) => {
  useEffect(() => {
  // eslint-disable-next-line
  }, []);

  const searchParameter = () => {
    fnSearch(
      document.getElementById("name").value,
      document.getElementById("code").value,
      'GENERAL'
    );
  };

  const searchAll = () => {
    document.getElementById("name").value = "";
    document.getElementById("code").value = "";
    fnSearchAll();
  };
  return (
    <IonRow className="d-flex align-items-center">
      <IonCol size="6" size-md="6">
        <IonItem>
          <IonInput
            onKeyUp={(e) => fnFilter(e, "name")}
            name="name"
            id="name"
            type="text"
            required
            placeholder="Nombre Artículo"
          ></IonInput>
        </IonItem>
      </IonCol>
      <IonCol size="6" size-md="6">
        <IonItem>
          <IonInput
            onKeyUp={(e) => fnFilter(e, "code")}
            name="code"
            id="code"
            type="text"
            required
            placeholder="Código Artículo"
          ></IonInput>
        </IonItem>
      </IonCol>
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
      &nbsp;  &nbsp;  &nbsp; 
      <IonCol  size="7">
        <CustomPaginatorShort pags={total} to={to} actualPage={page} fn={fnPaginate} setPage={setPage} />
      </IonCol>
      <div style={{display:'flex'}}>
      <IonCol  size="2">
       <IonButton onClick={searchParameter}>
         <IonIcon icon={search} />
       </IonButton>
     </IonCol>
     &nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     <IonCol  size="2">
       <IonButton onClick={searchAll}>
         <IonIcon icon={list} />
       </IonButton>
     </IonCol>
      </div>
      </div>
    </IonRow>
  );
};

export default SearchDetail;








